<template>
  <v-container class="gym-details__details flex-column align-stretch flex-nowrap pa-0 flex-grow-1">
    <div class="gym-details__img">
      <v-img :src="`${mainResource.url}?t=w&w=600`" :alt="$t('gyms.gymLogo')" contain />
    </div>
    <div class="pa-4 d-flex flex-column align-stretch flex-nowrap">
      <div class="title pb-3">{{ gym.name }}</div>
      <p>
        <span>{{ gym.address }}</span>
        <br />
        <span>{{ gym.postal_code }} {{ gym.city }}</span>
        <br />
        <span v-if="gym.phone_number">{{ gym.phone_number }}</span>
        <br />
        <span v-if="gym.url_website">
          <i>
            <a :href="gym.url_website" target="_blank" class="tl-text-muted">
              {{ $t('gym.website') }}&nbsp;<sup><v-icon :size="14">tl-open-in-new</v-icon></sup>
            </a>
          </i>
        </span>
        <br />
      </p>
      <v-spacer />
      <div>
        <v-btn
          shrink
          block
          raised
          color="primary"
          :loading="selecting"
          :disabled="selecting || !accessible"
          @click="$emit('select')"
        >
          {{ $t('sidenav.selectGym') }}
        </v-btn>
      </div>
    </div>
  </v-container>
</template>

<script>
export default {
  props: {
    gym: { type: Object, default: () => {} },
    selecting: { type: Boolean, default: false },
    accessible: { type: Boolean, default: false },
  },
  computed: {
    mainResource() {
      return this.gym.gym_resources.find(gr => gr.resource_type == 'main_impression') || {}
    },
  },
}
</script>
