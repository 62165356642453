<template>
  <div>
    <!-- <div
      class="tl-gym__map-preview d-flex justify-center"
      v-if="$vuetify.breakpoint.smAndUp"
      @click="mapLoadTriggered = true"
    >
      <v-img :src="require('./maps-screenshot.png')" min-width="1660" />
    </div>
    <tl-google-map
      v-if="$vuetify.breakpoint.smAndUp && mapLoadTriggered"
      :zoom="mapZoom"
      :center="mapCenter"
      :markers="markers"
      class="tl-gym__map"
    /> -->

    <tl-leaflet-map
      v-if="$vuetify.breakpoint.smAndUp"
      :zoom="mapZoom"
      :center="mapCenter"
      class="tl-gym__map"
      @click.native.stop
    >
      <tl-leaflet-marker v-for="(marker, i) in markers" :key="i" :lat-lng="marker.position" :on-click="marker.onClick">
        <l-tooltip :options="{ className: 'tl-map-tooltip', direction: 'bottom' }">
          {{ marker.label }}
        </l-tooltip>
      </tl-leaflet-marker>
    </tl-leaflet-map>

    <v-container fluid>
      <v-item-group v-model="selectedGym" class="tl-gyms d-flex flex-column">
        <v-card v-if="recentGyms.length" class="tl-gyms__recent elevation-4">
          <v-list class="tl-gyms__list" subheader>
            <v-list-item>
              <v-list-item-content>
                <v-list-item-title>{{ $t('gyms.visitedGyms') }}</v-list-item-title>
                <v-list-item-subtitle>{{ $t('gyms.visitedGymsSubhead') }}</v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
            <v-fade-transition group leave-absolute>
              <v-item v-for="gym in recentGyms" :key="gym.id" :value="gym" v-slot:default="{ active, toggle }">
                <tl-gyms-gym :gym="gym" :expanded="active" :toggle="toggle" />
              </v-item>
            </v-fade-transition>
          </v-list>
        </v-card>

        <v-card class="tl-gyms__all elevation-4">
          <v-layout column fill-height>
            <v-layout v-if="loading" class="tl-gyms__spinner" column align-center justify-center fill-height>
              <v-progress-circular :size="50" color="primary" indeterminate />
            </v-layout>
            <v-list v-else subheader class="grow">
              <v-layout v-if="!gyms.length" column align-center justify-center fill-height>
                <v-icon class="my-10" color="error" size="150">tl-warning</v-icon>
                <v-list-item class="text-center mb-10">
                  <v-list-item-title>{{ $t('generic.errorMsg') }}</v-list-item-title>
                </v-list-item>
                <v-list-item class="text-center">
                  <v-btn color="primary" @click="fetchGyms">{{ $t('generic.refresh') }}</v-btn>
                </v-list-item>
              </v-layout>
              <template v-else>
                <v-list-item>
                  <v-list-item-content>
                    <v-list-item-title>{{ $t('gyms.gyms') }}</v-list-item-title>
                    <v-list-item-subtitle>{{ $t('gyms.selectGym') }}</v-list-item-subtitle>
                  </v-list-item-content>
                  <v-text-field
                    v-model="search"
                    ref="search"
                    class="pt-0"
                    hide-details
                    single-line
                    style="max-width: 150px"
                    append-icon="tl-search"
                    clearable
                    :label="$t('generic.search')"
                  />
                </v-list-item>
                <v-fade-transition group leave-absolute>
                  <template v-for="country in gymsByCountry">
                    <div :key="country.countryCode">
                      <v-divider />
                      <v-subheader>{{ country.country }}</v-subheader>
                    </div>
                    <v-item
                      v-for="gym in country.gyms"
                      :key="gym.id"
                      :ref="gym.id_name"
                      :value="gym"
                      v-slot:default="{ active, toggle }"
                    >
                      <tl-gyms-gym :gym="gym" :expanded="active" :toggle="toggle" />
                    </v-item>
                  </template>
                </v-fade-transition>
                <v-list-item v-if="search && !gymsByCountry.length">
                  <v-list-item-title>{{ $t('generic.searchNoResults', { search: search }) }}</v-list-item-title>
                </v-list-item>
              </template>
            </v-list>

            <div v-if="$vuetify.breakpoint.xs && gyms.length && !search">
              <v-lazy>
                <div>
                  <v-divider />
                  <tl-leaflet-map
                    :zoom="mapZoom"
                    :center="mapCenter"
                    style="height: 700px"
                    disable-gestures
                    @click.native.stop
                  >
                    <tl-leaflet-marker
                      v-for="(marker, i) in markers"
                      :key="i"
                      :lat-lng="marker.position"
                      :on-click="marker.onClick"
                    >
                      <l-tooltip :options="{ className: 'tl-map-tooltip', direction: 'bottom' }">
                        {{ marker.label }}
                      </l-tooltip>
                    </tl-leaflet-marker>
                  </tl-leaflet-map>
                </div>
              </v-lazy>
            </div>
          </v-layout>
        </v-card>
      </v-item-group>
    </v-container>
  </div>
</template>

<script>
import toolbarMixin from '@/components/layout/toolbar/toolbar.mixin'
import { gymsByCountry } from './utils'
import { mapState } from 'vuex'
import Gym from '@/models/Gym'
import tlGymsGym from './tl-gyms-gym'
// import tlGoogleMap from '@/components/shared/google-map/tl-google-map.vue'
import tlLeafletMap from '@/components/shared/leaflet/tl-leaflet-map.vue'
import tlLeafletMarker from '@/components/shared/leaflet/tl-leaflet-marker.vue'
import { LTooltip } from 'vue2-leaflet'

export default {
  mixins: [toolbarMixin],
  components: {
    // tlGoogleMap,
    tlLeafletMap,
    tlLeafletMarker,
    LTooltip,
    tlGymsGym,
  },
  head() {
    return {
      title: this.$t('gyms.title'),
      meta: [{ vmid: 'description', name: 'description', content: this.$t('gyms.description') }],
    }
  },
  data() {
    return {
      loading: true,
      gyms: [],
      search: '',
      showSearch: false,
      selectedGym: null,
      // mapLoadTriggered: false,
    }
  },
  computed: {
    ...mapState(['user', 'gym']),
    ...mapState('nav-right', ['expanded']),
    tlToolbarTitle() {
      return this.$t(this.gym ? 'sidenav.switchGym' : 'sidenav.selectGym')
    },
    tlToolbarButtons() {
      if (!this.user.admin) return []
      return [{ icon: 'tl-add', text: 'Add gym', action: this.addGym }]
    },
    mapCenter() {
      let gym = this.selectedGym || {}
      return [gym.latitude || 48.8860459, gym.longitude || -1.7317197]
    },
    mapZoom() {
      return this.selectedGym ? 12 : 5
    },
    filteredGyms() {
      if (this.loading) return []
      return this.gyms.filter(gym => {
        let isLive = gym.live != false
        let isSetter = this.user.setters.some(s => s.gym_id == gym.id)
        let isAdmin = this.user.admin
        return isLive || isSetter || isAdmin
      })
    },
    filteredGymsWithSearch() {
      return this.filteredGyms.filter(gym => {
        return gym.name.toLowerCase().indexOf((this.search || '').toLowerCase()) > -1
      })
    },
    recentGyms() {
      return this.filteredGyms
        .filter(gym => gym.my_ascends_count > 0 && (!this.gym || gym.id !== this.gym.id))
        .sort((a, b) => b.my_ascends_count - a.my_ascends_count)
        .slice(0, 3)
    },
    markers() {
      return this.filteredGyms.map(gym => {
        return {
          label: gym.name,
          position: [gym.latitude || 0, gym.longitude || 0],
          onClick: () => this.toggleSelectedGym(gym),
        }
      })
    },
    gymsByCountry() {
      return gymsByCountry(this.filteredGymsWithSearch)
    },
  },
  mounted() {
    this.fetchGyms()
  },
  methods: {
    fetchGyms() {
      this.loading = true
      Gym.$apiAll({ params: { json_params: { includes: ['gym_resources'] } }, injectResponse: false })
        .then(gyms => {
          gyms.forEach(g => {
            g.latitude = parseFloat(g.latitude)
            g.longitude = parseFloat(g.longitude)
          })
          this.gyms = gyms
        })
        .finally(() => (this.loading = false))
    },
    toggleShowSearch() {
      this.showSearch = !this.showSearch
      if (this.showSearch) {
        this.$nextTick(() => this.$refs.search.focus())
      } else {
        this.search = ''
      }
    },
    toggleSelectedGym(gym) {
      this.selectedGym = gym
      if (this.selectedGym) {
        let refsArr = this.$refs[this.selectedGym.id_name]
        let el = refsArr && refsArr[0] && refsArr[0].$el
        if (el) el.scrollIntoView()
      }
    },
    addGym() {
      this.$store.dispatch('dialog/open', {
        component: () => import(/* webpackChunkName: 'tl-gym-add' */ './tl-gym-add.vue'),
        props: { maxWidth: 800 },
      })
    },
  },
}
</script>

<style lang="sass" scoped>
.tl-gym__map, .tl-gym__map-preview
  width: 100%
  height: 100%
  position: absolute
.tl-gym__map-preview
  overflow: hidden
.tl-gyms
  z-index: 2
  &__recent
    margin-bottom: 20px
  @include media-breakpoint('xs-only')
    width: 100%
    &__spinner
      padding-top: 40%
      padding-bottom: 40%
  @include media-breakpoint('sm-and-up')
    width: 400px
    position: absolute
    height: 95%
    &__all
      height: 100%
      overflow-y: scroll
      -webkit-overflow-scrolling: touch
</style>
