<template>
  <div class="tl-gyms-gym">
    <v-list-item @click="toggle">
      <v-list-item-avatar :size="54" class="mr-4">
        <img :src="iconSrc" :alt="iconAltText" />
      </v-list-item-avatar>

      <v-list-item-content>
        <v-list-item-title>{{ gym.name }}</v-list-item-title>
        <v-list-item-subtitle v-if="commingSoon">{{ $t('gyms.commingSoon') }}</v-list-item-subtitle>
      </v-list-item-content>

      <v-list-item-action>
        <v-btn icon ripple @click.stop="toggle">
          <v-icon color="grey lighten-1">tl-chevron-{{ expanded ? 'up' : 'down' }}</v-icon>
        </v-btn>
      </v-list-item-action>
      <v-list-item-action class="tl-gyms-gym__to-gym-btn">
        <v-btn icon ripple :loading="selecting" :disabled="selecting || !accessible" @click.stop="selectGym">
          <v-icon v-if="accessible" color="grey lighten-1">tl-arrow-forward</v-icon>
        </v-btn>
      </v-list-item-action>
    </v-list-item>
    <v-expand-transition>
      <div v-if="expanded">
        <tl-gym-details :gym="gym" :accessible="accessible" :selecting="selecting" @select="selectGym" />
        <v-divider />
      </div>
    </v-expand-transition>
  </div>
</template>

<script>
import { mapState, mapGetters } from 'vuex'
import tlGymDetails from './tl-gym-details.vue'
import Gym from '@/models/Gym'

export default {
  components: {
    tlGymDetails,
  },
  props: {
    gym: { type: Object, default: () => {} },
    expanded: { type: Boolean, default: false },
    toggle: { type: Function, default: () => {} },
  },
  data: () => ({
    selecting: false,
  }),
  computed: {
    ...mapState(['user']),
    ...mapState('kiosk', ['isKiosk']),
    ...mapGetters('kiosk', ['isKioskLocked']),
    iconSrc() {
      return `${Gym.getSrcImages(this.gym)}logo_100x100.png?v=1`
    },
    iconAltText() {
      return this.$t('gyms.gymLogo') + ' ' + this.gym.name
    },
    commingSoon() {
      return this.gym.live === undefined
    },
    accessible() {
      return this.gym.live || this.user.setters.some(s => s.gym_id == this.gym.id) || this.user.admin
    },
  },
  methods: {
    async selectGym() {
      if (!this.accessible) return
      if (this.isKiosk && !this.isKioskLocked) {
        // Prompt password before entering gym:
        let correctPwd = await this.$store.dispatch('kiosk/authKioskMode', { gym: this.gym })
        if (!correctPwd) return
      }
      this.selecting = true
      this.$router.push({
        name: 'dashboard',
        params: {
          gymSlug: this.gym.slug,
          climbsType: 'climbs',
        },
      })
    },
  },
}
</script>

<style lang="sass" scoped>
.tl-gyms-gym
  &__to-gym-btn
    @media (max-width: 340px)
      display: none
.v-list-item__avatar
  min-width: 70px
</style>
